.search-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    padding: 50px 20px;
    border-bottom: 1px solid #eee;
}

.search-bar .search {
    display: flex;
    align-items: center;
}

.search-bar input {
    padding: 10px;
    width: 400px;
    font-size: 24px;
    border-radius: 5px 0 0 5px;
}

.search-bar button {
    height: 50px;
    border-radius: 0 5px 5px 0;
    width: 100px;
    border: 1px solid #3a9bdc;
    background: #3a9bdc;
    color: #fff;
    outline: none;
}

.search-bar button:hover {
    background: #0583d2;
    border-color: #0583d2;
    cursor: pointer;
}

.search-bar button:disabled {
    background: #ccc;
    border-color: #ccc;
    cursor: default;
}

.find-in-results {
    margin-bottom: 2em;
}

.find-in-results input {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1em;
    border-radius: 4px;
}

.found {
    margin: 1em 0;
    font-size: 14px;
    color: #666;
}