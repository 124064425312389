* {
    font-family: sans-serif;
}

body {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh;
    padding: 0 50px 20px;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.App {
    display: flex;
    flex: 1;
    justify-content: center;
}

main.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
}

input {
    padding: 10px;
    text-align: center;
    color: #333;
    font-weight: 100;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

input:focus,
button:focus {
    border-color: #1260cc;
}

.bottom-container {
    display: flex;
    flex: 1;
}
