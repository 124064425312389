aside {
    height: 100%;
}

aside.previous-search-queries-container {
    width: 300px;
    padding-left: 1em;
    border-left: 1px solid #eee;
}

aside.previous-search-queries-container h2 {
    margin-top: 0;
}

.previous-search-queries {
    display: flex;
    flex-direction: column-reverse;
}

.previous-search-query {
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    background: #f9e09f;
    color: #000;
}

.previous-search-query:hover {
    background: #f6d56d;
}